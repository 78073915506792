<template>
  <div style="background-color: white;min-height: 600px">
    <div>
      <div style="height: 40px;padding: 20px 20px 0;border-bottom: 1px solid #EEEEEE;">
        <img style="display: inline-block;height: 24px;vertical-align: middle" src="../../assets/img/robot/phone_back.png" @click="goback()">
        <span style="font-size: 17px;font-weight: 500;vertical-align:middle;color: #000613;margin-left: 10px">{{ $t('user_center.daily_revenue_details') }}</span>
      </div>
      <div style="padding: 10px 20px;border-bottom: 1px solid #F0F2F5;">
        <span style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #333333;">{{ detailsItem.date }}{{ $t('user_center.robot_profit_total') }}(USDT)</span>
        <span style="display:block;margin-top: 10px;font-size: 20px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ detailsItem.trade_profit?detailsItem.trade_profit:'0.00' | roundTwoFilter | thousandth }}<span style="font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;"> ≈¥{{ detailsItem.trade_profit?(detailsItem.trade_profit * rate):'0.00' | roundTwoFilter | thousandth }}</span></span>
      </div>
      <!-- 黄金三角 -->
      <div v-if="details.triangle_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.auto') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.sj') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.triangle_count?details.triangle_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.triangle_profit_yield?(details.triangle_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ details.triangle_profit?(details.triangle_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ details.triangle_profit>=0?'+':'' }}{{ details.triangle_profit?details.triangle_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- BB -->
      <div v-if="details.bb_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.auto') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.bb_trade') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.bb_count?details.bb_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.bb_profit_yield?(details.bb_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ details.bb_profit?(details.bb_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ details.bb_profit>=0?'+':'' }}{{ details.bb_profit?details.bb_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- UB -->
      <div v-if="details.ub_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.auto') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.ub_trade') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.ub_count?details.ub_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.ub_profit_yield?(details.ub_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ details.ub_profit?(details.ub_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ details.ub_profit>=0?'+':'' }}{{ details.ub_profit?details.ub_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- 网格 -->
      <div v-if="details.grid_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.semi') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.grid_strategy') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.grid_count?details.grid_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.grid_profit_yield?(details.grid_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ details.grid_profit?(details.grid_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ details.grid_profit>=0?'+':'' }}{{ details.grid_profit?details.grid_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- 马丁 -->
      <div v-if="details.mg_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.semi') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.martin_strategy') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.mg_count?details.mg_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.mg_profit_yield?(details.mg_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ details.mg_profit?(details.mg_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ details.mg_profit>=0?'+':'' }}{{ details.mg_profit?details.mg_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- 合约 -->
      <div v-if="details.supreme_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.auto') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.grid_contract_strategy') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.supreme_count?details.supreme_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ details.supreme_profit_yield?(details.supreme_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ details.supreme_profit?(details.supreme_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ details.supreme_profit>=0?'+':'' }}{{ details.supreme_profit?details.supreme_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDayProfitByUserAndDate, getUSDTRateByCode} from "../../server/user";

export default {
  name: "profit_details_everyday",
  data(){
    return{
      isMobile: false,
      showProfitDetails: false,
      details: {},
      date: '',
      params:{
        year: '',
        month: '',
        profitDay: ''
      },
      detailsItem: {},
      rate: 0,
    }
  },

  mounted() {
    if (this.$util._isMobile()){
      this.isMobile = true
    } else{
      this.$router.go(-1)
    }
    this.detailsItem = JSON.parse(localStorage.getItem('detailsItem'))
    this.date = this.detailsItem.date
    this.getUSDTRateByCode()
    this.getDayProfitByUserAndDate()
  },

  methods:{
    async getUSDTRateByCode(){ // 获取实时换算比率
      const res = await getUSDTRateByCode()
      if (!res) return
      if (res.success) {
        this.rate = res.data
      }
    },

    async getDayProfitByUserAndDate(){ // 统计明细
      this.params.year = this.date.split('-')[0]
      this.params.month = this.date.split('-')[1].replace(/\b(0+)/gi,"")
      this.params.profitDay = this.date
      const res = await getDayProfitByUserAndDate(this.params)
      if (!res) return
      if (res.success){
        if (!res.data || res.data == null || res.data.length == 0) return
        this.details = res.data[0]
        this.showProfitDetails = true
      }
    },
    goback(){
      this.$router.go(-1)
    },
  },

  destroyed() {
    sessionStorage.removeItem('detailsItem')
  }
}
</script>

<style scoped>

.details_title {
  display: block;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  font-size: 12px;
}

</style>
